import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  template: `
    <div *ngFor="let alert of alerts; let i = index" class="alert alert-{{alert.type}} alert-dismissible" role="alert" style = "margin-top: 20px">
      <div>{{alert.message}}</div>
      <button type="button" class="btn-close" (click)="removeAlert(i)" aria-label="Close"></button>
    </div>
  `,
  styleUrl: './alert.component.css',
  standalone: true,
  imports: [CommonModule]

})
export class AlertComponent {
  @Input() alerts: any[];

  constructor() { }

  removeAlert(index: number) {
    this.alerts.splice(index, 1);
  }
}
